.infoadsettingmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.infoadsettingprotion1.row {
    margin-top: 2rem;
    width: 100%;
    margin-left: 0px;
}
.infoadsettingprotion22.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
}
.infoadsettingheading2.row {
    margin-top: 0rem;
    width: 100%;
    margin-left: 1px;
    font-weight: bold;
}
.infoadsettingheading3.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
}
.infoadsettingheading4.row {
    width: 50%;
    margin-left: 0px;
    font-family: 'BuenosAiresTrial-bold';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #283447;

}
.infoadsettingheading5.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
    font-family: 'BuenosAiresTrial';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #283447;
}
.infoadsettingheading6.row {
    /* margin-top: 1rem; */
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
    font-weight: bold;

}
.infoadsettingheading7.row {
    margin-top: 0rem;
    width: 100%;
    margin-left: 0px;
    font-family: 'BuenosAiresTrial-bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #283447;
}
.infoadsettingheading8.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;

}
.infoadsettingheading9.row {
    margin-top: 0rem;
    width: 100%;
    margin-left: 0px;
    font-family: 'BuenosAiresTrial-bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #283447;

}
.infoadsettingheading10.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;

}
.infoadsettingheading11.row {
    margin-top: 0rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
    font-weight: bold;

}
.infoadsettingheading12.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;

}
.topheadinginfoadsetting{
    width: 100%;
  height: auto;
  font-family: 'BuenosAiresTrial-bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
   ;
  color: #283447;
}
.infoadsettingstate.row {
    width: 100%;
    margin-left: 0px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
button.infoadsettingsubmit.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: auto;
    color: #FFFFFF;
    font-size: 13px;
  font-family: 'BuenosAiresTrial-Bold';
    color: #283447;
    margin-left: 0rem;
    }
    .infoadstatestss{
        width: 100%;
    margin-left: 0px;
    margin-top: 2rem;
    }
    .infoadbtn{
      font-family: 'BuenosAiresTrial';
        font-weight: bolnormald;
        font-size: 15px;
        color: #283447;
    }
    .infoad-wrapper{
        position: relative;
    overflow: hidden;
    /* / display: inline-block; /
    / justify-content: center; / */
    width: 100%;
    /* height: 30px; */
    margin-left: 0px;
    }
    .infoad-btnn{
        width: auto;
        height: 40px;
    border: 1px solid #283447;
    color: #283447;
    background-color: #f1f1f1;
    padding: 5px 20px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border-radius: 3%;
    margin-left: 0rem;
    }
    .infoad-wrapper input[type=submit] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
    .infoadsetting-btns.row{
      font-family: 'BuenosAiresTrial';
        font-weight: bold;
        font-size: 15px;
        color: #283447;

        }
        button.shareinfoadsetting.btn.btn-secondary {
            background-color: #75d0d4;
            border: 1px;
            width: auto;
            height: 40px;
            color: #283447;
            font-size: 15px;
          font-family: 'BuenosAiresTrial';
            margin-left: -1.5rem;
        }
        .btnsinfoad.row {
          font-family: 'BuenosAiresTrial';
            font-weight: normal;
            font-size: 15px;
            color: #283447;
        }
        button.dashboardshareinfoad.btn.btn-secondary {
            background-color: #283447;
            border: 1px;
            width: auto;
            height: 40px;
            color: #FFFFFF;
            font-size: 15px;
          font-family: 'BuenosAiresTrial';
          font-weight: normal;
        }
        .parentDisable{
            position: fixed;
            top: 0;
            left: 0;
            background: #666;
            opacity: 0.8;
            z-index: 998;
            height: 100%;
            width: 100%;
          }
          
          .overlay-box {
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            color: white; background: #666666; opacity: .8;
            z-index: 1000;
          }


    