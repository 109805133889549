.usermain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.usersprotion1.row {
    margin-top: 2rem;
    width: 100%;
    margin-left: 0px;
}
.usersprotion2.row {
    margin-top: 6rem;
    width: 100%;
    margin-left: 0px;

}
.topheadingusers{
    width: 100%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
   ;
  color: #283447;
}
.formusers.row {
    width: 100%;
    margin-left: 0px;
}
label.usersname-label {
    width: 100%;
    height: 18px;
  font-family: 'BuenosAiresTrial-Bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    margin-left: 10px;
    color: #283447;
    margin-top: 1rem;
    border: 1px;
}
input#users-userName {
  width: 90%;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
   ;
  color: #283447;
  border: none;
}
input#users-name {
    width: 90%;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    color: #283447;
    border: none;
}

select#users-userName {
  width: 90%;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    color: #283447;
    border: none;
}
textarea#exampleuserName {
  width: 90%;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
   ;
  color: #283447;
  border: none;
}
button.saveusers.btn.btn-secondary {
    background-color: #75d0d4;
    margin-top: 2rem;
    border: 1px;
    width: 100px;
    margin-bottom: 3%;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
  
}
.parentDisable{
    position: fixed;
    top: 0;
    left: 0;
    background: #666;
    opacity: 0.8;
    z-index: 998;
    height: 100%;
    width: 100%;
  }
  
  .overlay-box {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: white; background: #666666; opacity: .8;
    z-index: 1000;
  }

