.logoi{
  display: none;
}

.styles_overlay__CLSq-.undefined{
  background: none;
  width: 70%;
  margin: auto;
}

.styles_modal__gNwvD{
  margin:0;
}

.butHide {
    display: flex;
    margin-top: 30px;
}

.policyText{
  cursor: pointer;
  color: royalblue;
}
.image360box{
  width: 60%;
  margin:auto;
  margin-top:5%;
  display: inline-block;
  position: relative;
}

.image360Container{
    width: 100%;
    margin:auto;
  }

.image360{
  position: absolute;
  width: 66%;
  height: 78%;
  border: none;
  margin-top: 4%;
  right: 17%;
}

@media (max-width: 1500px) and (min-width: 900px){
  .image360box{
    width: 80%;
  }
}

@media (max-width: 900px){
  button.readmore1.btn.btn-secondary {
      display: block;
  }

  .image360box{
    width: 100%;
  }

}
