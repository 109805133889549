/* .footer{
    width: auto;
    height: 100vh;
  } */
.foot2b{
  width:100%;
}
.foot2a.row{
  justify-content:center;
}
  .section1{
    width: 100%;
    height: auto;
    background: #4b4949;
  }
  .row.brightbeetitle {
    font-family: 'BuenosAiresTrial-bold';
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
   
    
    color: #75c9cd;
  }
  .row.emailcol1 {
    font-family: 'BuenosAiresTrial';
    font-size: 21.8px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    margin-top: 0rem;
   
    color: #e8e8e8;
  }
  .row.topheading {
      width: 100%;
      margin-left: 0px;
    font-family: 'BuenosAiresTrial';
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #eeeeee;
    margin-top: 6rem;
  }
  .topheading1{
    
   
    
  font-family: 'BuenosAiresTrial';
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ececec;

  
}

  .secondtext {
    font-family: 'BuenosAiresTrial';
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ececec;
   
   
    margin-top: -1rem;
  }
  .row.thirdtext {
    font-family: 'BuenosAiresTrial';
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    color: #ffffff;
    width: 100%;
  
    margin-top: 0rem;
  }
  .section2{
    width: 100%;
    height: 20vh;
    background: #c4c3c3;
    justify-content: center;
    
  }
  .text4f{
   
   font-family: 'BuenosAiresTrial';
   font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #000000;
    margin:auto;
    text-align:center;
   
    
  }

  .mainrowss.row {
    width: 100%;
    height: auto;
   margin-left:0;
    background: #2a3445;
    padding: 8% 16%;
}
.section200.row {
    width: 100%;
    height: 20vh;
    background: #ececec;
    justify-content: center;
    padding-left:8.33%;
    padding-right:8.33%;
    margin:0;
    
}
.socialfbicon.row {
    
   
    margin-top: 5rem;
    
}

.row.thirdtext1{
  font-family: 'BuenosAiresTrial';
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ececec;
  
  text-align: center;
  padding-top:7rem;

}

.row.secondtext1{
  font-family: 'BuenosAiresTrial';
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ececec;
 margin-top:12rem;

  

}





.secondtext2{
 justify-content:center;
  margin-top: 1%;
  
}


@media (max-width:767px){
  .row.thirdtext1,.secondtext1.row{
  

font-size: 2vh;


 

  }
  }

  .foot1{
    margin-left:0%;
  }

 

    @media (max-width:767px){
      .socialfbicon.row {
      margin-top: 0;
      padding-top: 10%;
   
    
     
    
      }
      }

      @media (max-width:767px){
        img.fbicon {
       
        padding-bottom: 1.2rem;
     
      
       
      
        }
        }
    
        @media (max-width:767px){
          .row.brightbeetitle {
         
          font-size: 6vw;
       
        
         
        
          }
          }
          @media (max-width:767px){
            .row.emailcol1{
           
            font-size: 3vw;
         
          
           
          
            }
            }
            @media (max-width:767px){
              .topheading1,.secondtext{
             
              font-size: 2vh;
           
            
             
            
              }
              }
              @media (max-width:450px){
                .topheading1,.secondtext,.row.thirdtext1{
               
                font-size: 1.6vh;
             
              
               
              
                }
                }

                @media (max-width:376px){
                  .topheading1,.secondtext,.row.thirdtext1{
                 
                  font-size: 1.3vh;
               
                
                 
                
                  }
                  }

                  @media (max-width:320px){
                    .topheading1,.secondtext,.row.thirdtext1{
                   
                    font-size: 1.5vh;
                 
                  
                   
                  
                    }
                    }
  

             
            
              @media (max-width:991px){
                .thirdtext1.row{
               
                text-align:left;
                       
               
              
                }
                }

                @media (max-width:991px){
                  .secondtext2{
                    justify-content:left;
                    
                     
                   }
                  }

                  @media (max-width:767px){
                    .row.thirdtext1{
                      
                       padding-top:2rem;
                     }
                    }
                    @media (max-width:414px){
                      .row.thirdtext1{
                        
                         padding-top:1rem;
                       }
                      }
                      @media (min-width:1900px){
                        .row.thirdtext1,.topheading1{
                          
                           font-size:0.6vw;
                         }
                        }
                  
                        @media (max-width:991px){
                          .foot2a.row {
                            
                            justify-content: flex-start;
                           
                         
                           }
                          }

                          @media (width:768px){
                            .foot2a.row {
                              
                              margin-top:-2rem;
                             
                           
                             }
                            }
            

  /* test */
/* @media (max-width:375px){
  .row.secondtext1{
  
 
 width: 70%;

 

  }
  }

    
@media (max-width:768px){
  .row.secondtext1{
  

 
 width: 70%;

 

  }
  }
  
  @media (max-width:768px){
    .row.thirdtext1{
    
  
   
   width: 70%;
  margin-left: -10px;
   
  
    }
    }

 

  @media (max-width:768px){
    .socialfbicon.row{
    
   margin-left:50px;
   
  
  
   
  
    }
    }

    @media (max-width:768px){
      .row.thirdtext1{
      
     margin-left:40px;
     
    
    
     
    
      }
      }
  
      
    @media (max-width:768px){
      .row.secondtext1{
      
     margin-left:140px;
     
    
    
     
    
      }
      }
    
     
      @media (min-width:769px){
        .row.secondtext1{
        
       width: 70%;
       
      
      
       
      
        }
        }

      @media (max-width:768px){
        .Stock.row{
        
       width:70%;
       margin-top:50px;
       
      
      
       
      
        }
        }

        @media (max-width:1024px){
          .row.thirdtext1{
          
        
         margin-left:90px;
         
        
        
         
        
          }
          }   
       
          @media (max-width:1024px){
            .socialfbicon.row{
            
           margin-left:50px;
           margin-top:20px;
          
          
           
          
            }
            }

            
              
          @media (max-width:1024px){
            .row.brightbeetitle{
            
           
           margin-top:100px;
          
          
           
          
            }
            }

            @media (max-width:1024px){
              .row.topheading1{
              
             
             margin-top:120px;
            
            
             
            
              }
              }
  
            
              

              @media (max-width:375px){
                .row.thirdtext1{
                
               
               margin-left:50px;
              
              
               
              
                }
                } 

                
              @media (max-width:375px){
                .row.secondtext1{
                
               
               margin-left:50px;
              
              
               
              
                }
                } 

                @media (max-width:375px){
                  .Stock.row{
                  
                 
                 margin-left:60px;
                
                
                 
                
                  }
                  } 
                   */
/* tHIS IS NEW ONE */
                   /* @media (max-width:767px){
                    .thirdtext1.row{
                    
                  
                margin-left:0%;
                  
                   
                  
                    }
                    }

                    
                   @media (max-width:767px){
                    .secondtext1.row{
                    
                  
                margin-left:0%;
                  
                   
                  
                    }
                    }

                    @media (max-width:767px){
                      .secondtext2img{
                      
                    
                  margin-left:-55%;
                    
                     
                    
                      }
                      } */

                      
                    /* @media (max-width:414px){
                      .socialfbicon.row{
                      
                    
                  margin-left:30%;
                    
                     
                    
                      }
                      }
                     */

                     /* @media (max-width:768px){
                      .socialfbicon.row{
                      
                    
                  margin-left:35%;
                    
                     
                    
                      }
                      } */
/* 
                      @media (max-width:1300px){
                        .socialfbicon.row{
                        
                      
                    margin-left:15%;
                      
                       
                      
                        }
                        }

                        @media (max-width:990px){
                          .socialfbicon.row{
                          
                        
                      margin-left:32%;
                        
                         
                        
                          }
                          }

                          @media (max-width:767px){
                            .socialfbicon.row{
                            
                          
                        margin-left:11%;
                          
                           
                          
                            }
                            }

                            @media (max-width:650px){
                              .socialfbicon.row{
                              
                            
                          margin-left:15%;
                            
                             
                            
                              }
                              }

                              @media (max-width:550px){
                                .socialfbicon.row{
                                
                              
                            margin-left:25%;
                              
                               
                              
                                }
                                } */

                                  
                       
                              
  
                              
