.paymentmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.paymentprotion1.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
}
.paymentprotion2.row {
    margin-top: 8rem;
    width: 100%;
    margin-left: 0px;
}
.topheadingpayment{
    width: 100%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
   ;
  color: #283447;
}
.formpayment.row {
    width: 100%;
    margin-left: 0px;
}
label.paymentname-label {
    width: 100%;
    height: 18px;
  font-family: 'BuenosAiresTrial-Bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    margin-left: 10px;
    color: #283447;
    margin-top: 1rem;
    border: 1px;
}
input#paymentuserName {
  width: 90%;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
   ;
  color: #283447;
  padding-left: 4%;
}
textarea#exampleuserName {
    width: 90%;
  font-family: 'BuenosAiresTrial-Bold';
    color: #283447;
}
button.savepayment.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: auto;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    color: #283447;
}
.btn-wrapper-payment {
    position: relative;
    overflow: hidden;
    /* / display: inline-block; /
    / justify-content: center; / */
    width: 100%;
    margin-left: 0px;
  }
  
  .btn-payment {
    width: auto;
    border: 1px solid #283447;
    color: #283447;
    background-color: #f1f1f1;
    padding: 8px 20px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
  font-style: normal;
  border-radius: 3%;
  }
  .btn-wrapper-payment input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  .paymentsaverow.row {
    margin-left: 0px;
    margin-top: 9%;
}
.StripeElement {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-family: 'BuenosAiresTrial-Bold';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.paymentMethodProtion1.row {
  margin-top: 1rem;
  width: 100%;
  margin-left: 0px;
}
.paymentMethodProtion2.row {
  margin-top: 1rem;
  width: 100%;
  margin-left: 0px;
}
.paymentMethodDetail {
  width: 100%;
    height: 18px;
    font-family: 'BuenosAiresTrial-Bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    margin-left: 10px;
    color: #283447;
    margin-top: 1rem;
    border: 1px;
}
button.paymentMethodbutton.btn.btn-secondary {
  margin: 1rem 0.5rem;
  background-color: #75d0d4;
  border: 1px;
  width: auto;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
}
button.paymentMethodbutton2.btn.btn-secondary {
  margin: 1rem 0.5rem;
  background-color: rgb(255, 8, 69);
  border: 1px;
  width: auto;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: white
}
