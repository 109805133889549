@media (min-width: 1800px){

  .usermain>.offset-lg-1:first-of-type {
      margin-left: 25.333333%;
      max-width: 16%;
  }

  .usermain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .usermain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
      margin-left: 8%;
  }

  .userList>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }
}

@media (max-width: 1800px) and (min-width: 1600px){

  .usermain>.offset-lg-1:first-of-type {
      margin-left: 24.333333%;
      max-width: 20%;
  }

  .usermain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .usermain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
      margin-left: 8%;
  }

  .userList>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 17%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }
}

@media (max-width: 1600px) and (min-width: 1450px){

  .usermain>.offset-lg-1:first-of-type {
      margin-left: 23.333333%;
      max-width: 20%;
  }

  .usermain>.offset-lg-1:nth-of-type(2) {
      margin-left: 2%;
      flex: 0 0 33.333333%;
  }

  .usermain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
      margin-left: 8%;
  }

  .userList>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }
}

@media (max-width: 1450px) and (min-width: 1150px){

  .usermain>.offset-lg-1:first-of-type {
      margin-left: 23.333333%;
      max-width: 21%;
  }

  .usermain>.offset-lg-1:nth-of-type(2) {
      margin-left: 2%;
      flex: 0 0 33.333333%;
  }

  .usermain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
      margin-left: 8%;
  }

  .userList>.navbar-custom-1 {
    padding-left: 23%;
    padding-right: 20%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }
}

@media (max-width: 1150px) and (min-width: 1000px){

  .usermain>.offset-lg-1:first-of-type {
      margin-left: 22.333333%;
      max-width: 22%;
  }

  .usermain>.offset-lg-1:nth-of-type(2) {
      margin-left: 1%;
      flex: 0 0 33.333333%;
  }

  .usermain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
      margin-left: 8%;
  }

  .userList>.navbar-custom-1 {
    padding-left: 23%;
    padding-right: 20%;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-top:0%;
  }
}

@media (max-width: 1000px) and (min-width: 9500px){

  .usermain>.offset-lg-1:first-of-type {
      margin-left: 20.333333%;
      max-width: 16%;
  }

  .usermain>.offset-lg-1:nth-of-type(2) {
      margin-left: 5%;
      flex: 0 0 33.333333%;
  }

  .usermain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
      margin-left: 8%;
  }

  .userList>.navbar-custom-1 {
    padding-left: 21%;
    padding-right: 20%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }
}
