.agentcampaignmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.agentcampaignprotion1.row {
    margin-top: 5rem;
    width: 100%;
    margin-left: 0px;
}
.listingadprotion2.row {
    margin-top: 5rem;
    width: 100%;
    margin-left: 0px;
}
.agentheading2.row {
    margin-top: 5rem;
    width: 100%;
    margin-left: 0px;
    font-weight: bold;
}
.agentheading3.row {
    margin-top: -1rem;
    width: 60%;
    margin-left: 0px;
    font-size: 12px;
}
.agencyheading4.row {
    width: 55%;
    margin-left: 0px;
}
.agencyheading5.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
}
.agencyheading6.row {
    /* margin-top: 1rem; */
    width: 100%;
    margin-left: 0px;
}
.agencyheading7.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
}
.topheadingagentcampaign{
    width: 100%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
}
.agentstate.row {
    width: 100%;
    margin-left: 0px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
button.agentsubmit.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: auto;
    color: #FFFFFF;
    font-size: 13px;
  font-family: 'BuenosAiresTrial-Bold';
    color: #283447;
    margin-left: 0rem;
    }


    