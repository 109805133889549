.modal-dialog{
    display: flex;
    height: 100%;
}
.modal-content{
    max-width: 50%;
    padding: 3%;
    border-radius: 1rem;
    margin: auto;
    background-color: #ececec;
}
.modal-header{
    border: none;
    width: 100%;
}

.modal-title{
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #283447;
    font-family: 'BuenosAiresTrial-bold';
    font-size: 3vw;
    font-weight: bold;
}

.modal-body{
    font-family: BuenosAiresTrial;
    font-size: 1.6vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #283447;
    width: 100%;
}

.modal-footer{
    border: none;
    width: 100%;
    justify-content: left;
}

.but22{
    width: 102px;
    height: 46px;
    background-color: #75c9cd;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    border-radius: 5px;
    border: 1px solid #75c9cd;
    color: #283447;
}

button.readmore2.btn.btn-secondary {
    height: 46px;
    background-color: #2a3445;
    width: 100%;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;  
}

@media (max-width: 767px) {
    .modal-title {
        font-size: 4vh;
}}

@media (max-width: 767px) {
    .modal-body {
        font-size: 2.6vh;
}}
@media (max-width: 768px) {
    .modal-content {
        max-width: 80%;
}}
