.allcampaignmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh; 
    margin-top: 0rem;
}
.allcampaignprotion1.row {
    margin-top:  0rem;
    width: 89%;
    margin-left: 0px;
    /* border: 1px solid; */
    margin-left: 5%;
}
.allcampaigntopheading{
    width: auto;
    /* height: auto; */
  font-family: 'BuenosAiresTrial-Bold';
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.19; */
    letter-spacing: normal;
    color: #283447;
  }
  .allcampaigncardportionlast.row {
    width: 90%;
    height: auto;
    margin-bottom: 7rem;
    margin-left: 0px;
    /* margin-top: -10rem; */
    background: #f1f1f1;
    border-radius: 0%;
    box-shadow: initial;
    margin-left: 5%;
    padding-bottom: 1rem;
}
.allcampaigncampaignlistbottom.row {
    justify-content: center;
}
.allcampaigncardsizebottom{
    width: 90%;
    border-radius: 3% ;
    margin-top: 4%;
}
.deleteButton{
    background-color: rgb(255, 8, 69);
    color: white;
    
}
.dataNotFound{
    color: silver;
    justify-content: center;
    margin-top: 15rem;
}
.titleofallcampaign.card-title {
    font-size: 15px;
    font-weight: bold;
    margin-left: 2%;
    margin-top: 1%;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 3%;
    padding-bottom: 0%;
}
.subtitleofallcamapign.card-subtitle {
    font-size: 13px;
    margin-left: 2%;
    font-weight: normal;
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 2%;

}
.allcampaignbottomcardimg {
    height: auto;
    width: 100%;
    object-fit: fill;
}
.allcampaignbtmtext.row{
    background: #f1f1f1;
    color: #283447;
    justify-content: center;
    width: 100%;
    margin-left: 0px;
    padding: 3%;
}
.contentcenterallcampaign.row {
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px;
    font-family: BuenosAiresTrial;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #283447;
    padding: 5%;
}
.contentcenterallcampaigneye.row {
    -ms-flex-pack: right;
    justify-content: center;
    font-size: 12px;
    font-family: BuenosAiresTrial;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #283447;
    padding: 5%;
}
.contentcenterallcampaigneye1.row {
    -ms-flex-pack: right;
    justify-content: center;
    font-size: 12px;
    font-family: BuenosAiresTrial;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #283447;
    padding: 5%;
}
.activeadcardbtmtxtrow.row{
    background: #FFFFFF;
    color: #283447;
    justify-content: center;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 2%;
    padding: 2%;
}
.allcampaigncardbtmtxtrow.row{
    background: #FFFFFF;
    color: #283447;
    justify-content: center;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 2%;
}
.allcampaigncancel.row {
    padding-left: 40%;
    color: #ff4d24;
    font-size: 15px;
    padding-top: 2%;
}
.allcampaignview.row {
    color: #283447;
    font-size: 15px;
    padding-top: 2%;
    justify-content: flex-end;
    padding-right: 50%;
}
select#allcampaignsselect {
    /* margin-top: 5rem; */
    width: auto;
    border: 1px;
    margin-left: auto;
    height: auto;
}
button.allcampaignscreatead.btn.btn-secondary {
    background-color: #75d0d4;
    /* margin-top: 5rem; */
    border: 1px;
    width: auto;
    height: auto;
    margin-left: auto;
}
