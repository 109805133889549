.createcampaignmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.createcampaignprotion1.row {
    margin-top: 4rem;
    width: 100%;
    margin-left: 0px;
}
.createcampaignprotion2.row {
    margin-top: 3rem;
    width: 100%;
    margin-left: 0px;
}
.topheadingcreatecampaign{
    width: 100%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
}
.formcreatecampaign.row {
    width: 100%;
    margin-left: 0px;
}
label.createcampaignname-label {
    width: 100%;
    height: 18px;
  font-family: 'BuenosAiresTrial-Bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    margin-left: 10px;
    color: #283447;
    margin-top: 1rem;
    border: 1px;
}
input#paymentuserName {
  width: 90%;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
    padding-left: 4%;

}
select#paymentuserName {
    width: 90%;
  font-family: 'BuenosAiresTrial-Bold';
    color: #283447;
    border: none;
}
textarea#paymentuserName {
    width: 90%;
  font-family: 'BuenosAiresTrial-Bold';
    color: #283447;
    border: none;
}
textarea#exampleuserName {
    width: 90%;
  font-family: 'BuenosAiresTrial-Bold';
    color: #283447;
}
button.createcampaignsubmit.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: auto;
    color: #FFFFFF;
    font-size: 13px;
  font-family: 'BuenosAiresTrial-Bold';
    color: #283447;
    margin-left: 5.5rem;
    }
    .pdf-btn-wrapper-createcampaign {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-left: 0px;
    }
    .createcampaign-img-btn {
    width: auto;
    border: 1px solid #283447;
    color: #283447;
    background-color: #f1f1f1;
    padding: 8px 20px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border-radius: 3%;
    }
    .pdf-btn-wrapper-createcampaign  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    }
    .createcampaignbtns.row {
  font-family: 'BuenosAiresTrial-Bold';
    font-weight: bold;
    font-size: 25px;
    color: #283447;
    }
    .createcampaignstate.row {
        width: 100%;
        margin-left: 0px;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }