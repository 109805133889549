.shoecaseportfoliomain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.showcaseportfolioprotion1.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
}
.showcaseportfolioprotion2.row {
    margin-top: 2rem;
    width: 100%;
    justify-content: center;
    margin-left: 0px;
}
.topheadingshowcaseportfolio{
    width: 100%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
   ;
  color: #283447;
}
.formshowcaseportfolio.row {
    width: 100%;
    margin-left: 0px;
}
label.showcaseportfolio-label {
    width: 100%;
    height: 18px;
  font-family: 'BuenosAiresTrial-Bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    margin-left: 18px;
    color: #283447;
    margin-top: 1rem;
    border: 1px;
}

.showcaseportfoliostats{
    width: 100%;
margin-left: 0px;
margin-top: 2rem;
}

button.portfolionext.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: 100px;
    height: 40px;
    color: #283447;
    font-size: 13px;
  font-family: 'BuenosAiresTrial';
    margin-left: 10px;
}

.portfolionext-btn.row{
  font-family: 'BuenosAiresTrial';
    font-weight: normal;
    font-size: 15px;
    color: #283447;
    }
.checkboxesshowcase.row {
    width: 100%;
    margin-top: 1rem;
}
.portfoliocheckboxess.row{
    margin-left: 4%;
    width: 100%;
}
.portfoliocontainer2{
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #000000;
}
.portfoliocontainer2 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .portfoliocontainer2:hover input ~ .portfoliocheckmark2 {
    background-color: #FFFFFF;
  }
  .portfoliocontainer2 input:checked ~ .portfoliocheckmark2 {
    background-color: #0b8b64;
  }
  .portfoliocontainer2 input:checked ~ .portfoliocheckmark2:after {
    display: block;
  }
  .portfoliocontainer2 .portfoliocheckmark2:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .portfoliocheckmark2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border: 1px solid;
  }
  .portfoliocheckmark2:after {
    content: "";
    position: absolute;
    display: none;
  }