.topheader1.row {
    background: #4285f4;
    justify-content: center;
    color: #FFFFFF;
    font-family: inherit;
    height: 50px;
    margin-top: 120px;
}
.topheadernew2.row {
    margin-top: 65px;
}
p.compname {
    font-size: x-large;
    margin-top: 6px;
}
.centertext.row {
    -ms-flex-pack: center;
    justify-content: center;
    color: #4285f4;
    padding-top: 20px;
    font-size: 2rem;
    font-family: inherit;
}
.leftarrow.row {
    justify-content: center;
    padding-top: 20px;
}
.rightarrow.row {
    justify-content: center;
    padding-top: 20px;
}
.displaydatenew22.row {
    margin-top: 2rem;
    width: 100%;
    margin-left: 0%;
    font-size: 2rem;
    background: #eeeeee;
    border-bottom: 2px solid #bbbba7;
}
.textnew12.row {
    width: 90%;
    color: #a8a8a8;
    font-size: smaller;
    font-family: auto;
    margin-left: 2%;
}
.testnew22.row {
    width: 90%;
    margin-left: 2%;
    padding-bottom: 8px;
}
.idupdate.row {
    width: 100%;
    margin-left: 1px;
    margin-top: 40px;
    color: #FA4623;
}
.nameupdate.row {
    width: 100%;
    margin-left: 1px;
    margin-top: 25px;
    color: #FA4623;
}
p.comname {
    font-size: 1.3rem;
    margin-left: 1%;
}
.resourceupdate.row {
    width: 100%;
    margin-left: 1px;
    margin-top: 25px;
    color: #FA4623;
}
.resourceidupdate.row {
    width: 100%;
    margin-left: 1px;
    margin-top: 25px;
    color: #FA4623;
}
p.labels {
    font-size: 1.3rem;
    margin-left: 1%;
    color: black;
}
button.btnnext33.btn.btn-secondary {
    font-size: x-large;
    background: #4285f4;
    color: #FFFFFF;
    text-decoration: none;
    width: 400px;
    height: 55px;
    cursor: pointer;
    border: 2px;
    border-radius: 0.5rem;
}
.modalfootercustom {
    margin-left: auto;
    border: none;
    width: 40%;
    margin-top: 4%;
    margin-bottom: -2%;
}
p.cancelbtn {
    color: #FA4623;
}
p.addbtn {
    color: #FA4623;
}
.datenew1.row {
    width: 100%;
    margin-left: inherit;
}
.datenew2.row {
    width: 100%;
    margin-left: inherit;
}
.commpaignname.row {
    width: 100%;
    margin-left: 1px;
}
input#compaignname {
    height: 3rem;
    border: #b0afac;
    width: 100%;
    border-bottom: 2px solid #bdbdbd;
    font-size: 1.3rem;
}
input#datenew11 {
    height: 3rem;
    border: #b0afac;
    width: 100%;
    border-bottom: 2px solid #bdbdbd;
    font-size: 1.3rem;
}
input#datenew22 {
    height: 3rem;
    width: 100%;
    border: #b0afac;
    border-bottom: 2px solid  #bdbdbd;
    font-size: 1.3rem;
}
input#datenew33 {
    height: 5rem;
    border: #b0afac;
    border-bottom: 2px solid #bdbdbd;
    font-size: 1.3rem;
}
p.modal-heading-text1 {
    font-size: 1.5rem;
    font-weight: 600;
}
p.modal-heading-text2 {
    font-size: 0.9rem;
    color: #cbcaca;
}
p.modal2-heading-text1 {
    font-size: 1.5rem;
    font-weight: 600;
    border-bottom: 1px solid #c7c2c2;
}
p.modal2-heading-text2 {
    font-size: 0.9rem;
    color: #cbcaca;
}
.nextbtn33.row {
    width: 100%;
    margin-left: 1px;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 2%;
}
.centertext33.row {
    -ms-flex-pack: center;
    justify-content: center;
    color: black;
    padding-top: 20px;
    font-size: 2rem;
    font-family: inherit;
}
/* div#root {
    height: 100vh;
    width: 100%;
} */
input#region {
    height: 3rem;
    width: 100%;
    border: #b0afac;
    border-bottom: 2px solid  #bdbdbd;
    font-size: 1.3rem;
}
.react-date-picker__wrapper {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}