.createafternotemain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.createafternoteprotion1.row {
    margin-top: 2rem;
    width: 100%;
    margin-left: 0px;
}
.createafternoteprotion2.row {
    margin-top: 2rem;
    width: 100%;
    margin-left: 0px;
    justify-content: center;
}
.topheadingcreateafternote{
    width: 100%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
}
.formcreateaafternote.row {
    width: 100%;
    margin-left: 0px;
}
label.createafternote-label {
    width: 100%;
    height: 18px;
  font-family: 'BuenosAiresTrial-Bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    margin-left: 10px;
    color: #283447;
    margin-top: 1rem;
    border: 1px;
}
label.createafternoteOR-label {
    width: 100%;
    height: 18px;
  font-family: 'BuenosAiresTrial';
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    margin-left: 10px;
    color: #283447;
    margin-top: 1rem;
    border: 1px;
}
input#createafternoteinput {
    width: 90%;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
    border: none;
}

textarea#createafternoteinput {
  width: 90%;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
  border: none;
}

button.createafternotesubmit.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: auto;
    height: 40px;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
    margin-left: 5.5rem;
    }
    .createafternote-btn {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-left: 0px;
    }
    .createafternote-img-btn {
    width: auto;
    border: 1px solid #283447;
    color: #283447;
    background-color: #f1f1f1;
    padding: 8px 20px;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
    border-radius: 3%;
    }
    .createafternote-btn  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    }
    .createafternotebtns.row {
  font-family: 'BuenosAiresTrial';
    font-weight: bold;
    font-size: 15px;
    color: #283447;
    }
    .createafternotestate.row {
        width: 100%;
        margin-left: 0px;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    select#targetinputafternote {
        border: none;
        width: 90%;
        font-family: BuenosAiresTrial;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #283447;
    }
    .parentDisable{
        position: fixed;
        top: 0;
        left: 0;
        background: #666;
        opacity: 0.8;
        z-index: 998;
        height: 100%;
        width: 100%;
      }
      
      .overlay-box {
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        color: white; background: #666666; opacity: .8;
        z-index: 1000;
      }