.parentDisable{
    position: fixed;
    top: 0;
    left: 0;
    background: #666;
    opacity: 0.8;
    z-index: 998;
    height: 100%;
    width: 100%;
  }
  
.overlay-box {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: white; background: #666666; opacity: .8;
    z-index: 1000;
  }
.signup{
    padding-top: 5%;
    padding-bottom: 5%;
    margin: 0;
}

.gets{
    margin: 0;
    padding-bottom:5%;
}
.getsp{
    font-family: BuenosAiresTrial-Bold;
    font-size: 3.5vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #283446;
}

.customFieldRows{
    margin: 0;
    width: 100%;
}

.dataInputBlock {
    border: none;
    padding-bottom: 5%;
}

.form{
   margin-top: 3%;
   margin-left: 7%;
}

.form-control{
    width: 100%;
    height: 41.4px;
    border: none;
}
.form-group{
    padding: 8%;
}


.label{
    font-family: BuenosAiresTrial-Bold;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #283446;
    margin-left:2%;

}

.label1{
    font-family: BuenosAiresTrial-Bold;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #283446;
    margin-left:8%;

}

.in{
    width: 90%;
    height: 41.4px;
}

.formi{
    padding-right: 0;
    font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #283446;
  height: 41.4px;
width: 259px;
margin-top: -20px;


}


.selecti{
border-radius: 10px;
}

.checkbox{
    width: 16px;
    height: 16px;
    margin-top: 0px;
  
  background-color: #198865;
}

.checkl{
  
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #283446;
    margin-left: 20px;
    margin-top: 6%;
    
}
.pcheck{
    margin-left: 6%;
    font-family: BuenosAirestrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #283446;
    width: 100%;
}

.signupb{
    width: 102px;
    height: 46px;
    background-color: #75c9cd;
    font-family:  BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    border: 1px solid #75c9cd;
    color: #283447;
    margin-bottom: 5%;
}

.form2{
    margin-top: 27.3%;
    width: 100%;
    margin-left:-22%;
}
.signupimage{
    margin: 0;
    justify-content: center;
}

input.form-control::placeholder { 
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #283446;
    opacity: 1; 
    padding-left: 3%;
}

@media(max-width:575px)
{
    .label1{
        margin-left:2%;
        margin-top:5%;
}}

@media(max-width:767px)
{
    .getsp{
        font-size: 4.5vh;
}}
