.newagentadmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh; 
    margin-top: 0rem;
}
.newagentadprotion1.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
}
.newagentadtopheading {
  width: 55%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
font-size: 43px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.19;
letter-spacing: normal;
color: #283447;
}
.newagentadstate.row {
    width: 100%;
    margin-left: 0px;
    margin-top: 2rem;
    text-decoration: none;
}
.newagentadlistbottom.row {
    justify-content: center;
    text-decoration: none;
}
.newagentadcardsizebottom{
    width: 90%;
    border-radius: 3% ;
    margin-top: 4%;
    text-decoration: none;
}
.titleofnewagentad {
    font-size: 15px;
    font-weight: bold;
    margin-left: 0%;
    margin-top: 3%;
    color: #283447;
  font-family: 'BuenosAiresTrial-Bold';
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #FFFFFF;
    width: 100%;
    padding-left: 8%;
    padding-top: 4%;
    padding-bottom: 4%;
    text-decoration: none;
}
.chooseimagecssagentad.row {
    width: 100%;
    background: #ffded9;
    margin-left: 0px;
    justify-content: center;
    padding-top: 7%;
    text-decoration: none;

}
.agentadtext.row {
    width: 100%;
    background: #ffded9;
    margin-left: 0px;
    justify-content: center;
    padding-top: 7%;
    padding-left: 2%;
  font-family: 'BuenosAiresTrial';
    font-size: 15px;
    text-decoration: none;
    color: #283447;
}
.agentadbtmbutton.row {
    width: 100%;
    background: #283447;
    font-family: 'Helvetica';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;
    margin-left: 0px;
    justify-content: center;
    padding-top: 4%;
    padding-left: 4%;
    padding-right: 4%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-decoration: none;
}
