.publishpreviewadmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.publishpreviewadtargets.row {
    width: 100%;
    margin-left: 0px;
}
.publishpreviewadtopheading{
    width: 100%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
   ;
  color: #283447;
}
.publishpreviewadprotion1.row {
    margin-top: 2rem;
    width: 100%;
    margin-left: 0px;
}
.infoadsettingprotion2.row {
    margin-top: 0rem;
    width: 100%;
    margin-left: 0px;
}
.publishpreviewadheading2.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
    font-weight: bold;
}
.publishpreviewadheading3.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
}
.publishpreviewadheading4.row {
    width: 50%;
    margin-left: 0px;
    font-size: 13px;
    font-weight: bolder;
  font-family: 'BuenosAiresTrial-Bold';
}
.publishpreviewadheading5.row {
    margin-top: -1rem;
    width: 80%;
    margin-left: 0px;
    font-size: 15px;
    font-family: 'BuenosAiresTrial';
    font-weight: normal;
}
.publishpreviewadheading6.row {
    /* margin-top: 1rem; */
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
    font-weight: bold;

}
.publishpreviewadheading7.row {
    margin-top: 0rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
    font-weight: bold;
}
.publishpreviewadheading8.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;

}
.publishpreviewadheading9.row {
    margin-top: 0rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
    font-weight: bold;

}
.publishpreviewadheading10.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;

}
.publishpreviewadheading11.row {
    margin-top: 0rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
    font-weight: bold;

}
.publishpreviewadheading12.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;

}
.publishpreviewadbuttons{
    width: 100%;
    margin-left: 0px;
    margin-top: 2rem;
}
.publishpreviewadbtn{
    width: auto;
      font-family: 'BuenosAiresTrial';
        font-weight: normal;
        font-size: 15px;
        color: #283447;
}
.publishpreviewad-wrapper{
        position: relative;
    overflow: hidden;
    /* / display: inline-block; /
    / justify-content: center; / */
    width: 100%;
    margin-left: 0px;
}
.previewpublish-btnn{
        width: auto;
    border: 1px solid #283447;
    color: #283447;
    background-color: #f1f1f1;
    padding: 8px 20px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    border-radius: 3%;
    margin-left: 1rem;
}
    .publishpreviewad-wrapper input[type=submit] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
    .publishpreviewad-btns.row{
      font-family: 'BuenosAiresTrial-Bold';
        font-weight: bold;
        font-size: 25px;
        color: #283447;
        }
        button.sharepublishpreviewad.btn.btn-secondary {
            background-color: #75d0d4;
            border: 1px;
            width: auto;
            height: 40px;
            color: #283447;
            font-size: 15px;
          font-family: 'BuenosAiresTrial';
            margin-left: -3rem;
        }
        .parentDisable{
            position: fixed;
            top: 0;
            left: 0;
            background: #666;
            opacity: 0.8;
            z-index: 998;
            height: 100%;
            width: 100%;
          }
          
          .overlay-box {
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            color: white; background: #666666; opacity: .8;
            z-index: 1000;
          }
    


    