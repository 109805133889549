.editadmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.editadprotion1.row {
    margin-top: 2rem;
    width: 100%;
    margin-left: 0px;
}
.editadprotion2.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
    justify-content: centers;
}
.topheadingeditadnew{
    width: 100%;
  height: auto;
  font-family: 'BuenosAiresTrial-bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
}
.formeditadnew.row {
    width: 100%;
    margin-left: 0px;
}
label.editadnewname-label {
    width: 100%;
    height: 18px;
  font-family: 'BuenosAiresTrial-Bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    margin-left: 10px;
    color: #283447;
    margin-top: 1rem;
    border: 1px;
}
select#editadnewuserName {
    width: 90%;
  font-family: 'BuenosAiresTrial-Bold';
    color: #283447;
    border: none;
}

button.savesettingeditad1.btn.btn-secondary {
    background-color: transparent;
    border: 1px;
    width: auto;
    color: red;
    font-size: 13px;
  font-family: 'BuenosAiresTrial-Bold';
    margin-left: 0rem;
    /* margin-left: 1rem; */
}

button.savesettingeditad2.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: auto;
    color: #283447;
    font-size: 13px;
  font-family: 'BuenosAiresTrial-Bold';
    /* margin-left: 1rem; */
}

button.savesettingeditad3.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: auto;
    height: 40px;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
    padding-left: 35%;
    padding-right: 35%;

  }
  .settingsaveroweditad.row {
      width: 90%;
    margin-left: 0px;
    margin-top: 9%;
}
.data1data2editadnew.row {
    width: 90%;
    margin-left: 0px;
}
.data1roweditadnew.row {
    border: none;
    width: auto;
}
.data2roweditad.row {
    width: auto;
    margin-left: 1%;
    border: none;
}
select#targetinputeditad {
    border: none;
    width: 90%;
}
input#startdateinputeditad {
    border: none;
    width: 100%;
}
input#enddateinputeditad {
    border: none;
    width: 100%;
    font-family: 'BuenosAiresTrial';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
}
select#countryinputeditadnew {
    border: none;
    width: 90%;
}
input#countryinputeditadnew {
    border: none;
    width: 90%;
}
.agencycampaignprotion2 {
    justify-content: center;
}
.editadnew-img-btnn {
    width: auto;
    border: 1px solid #283447;
    color: #283447;
    background-color: #f1f1f1;
    padding: 6px 16px;
    font-family: 'BuenosAiresTrial';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    border-radius: 10%;
    }
    .btn {
        width: auto;
        border: 1px solid #283447;
        color: #283447;
        background-color: #f1f1f1;
        padding: 8px 20px;
        font-family: 'BuenosAiresTrial';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
    }
    .btn-wrapper-editadnew  input[type=submit] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
    .btn-wrapper-editadnew {
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-left: 0px;
        justify-content: center;
        }
    p.cancelad {
        color: #ff4d24;
      font-family: 'BuenosAiresTrial';
      font-size: 15px;
      font-weight: normal;
      text-decoration: underline;
      padding-top: 6%;
      cursor: pointer;
    }
    .roweditadcancel.row {
        justify-content: center;
    }
    .justitybutton.row {
        justify-content: flex-end;
        width: 100%;
        margin-left: 0px;
    }
    td.col-11.socialInputeditad {
        padding: 1px 8px 1px 1px;
        border: none;
    }
    .table td, .table th {
      padding: .75rem;
      vertical-align: top;
      /* border-top: 1px solid #dee2e6; */
  }
  .crosssighnseditad.row {
    z-index: 1;
    margin-left: -12%;
    margin-top: 8px;
    cursor: pointer;
    background: #ffffff;
    height: 25px;
    width: 19px;
    text-align: center;
    justify-content: center;
}
  tr.row.Socialeditad:hover {
    background: none;
  }
  input.selectedresults.form-control {
    width: 95%;
    margin-left: 3%;
}