

.navbar-custom-1 {
    box-shadow: 0 1px 4px 0 rgba(211, 211, 211, 0.5);
    padding-left: 5%;
    padding-right: 5%;
    background: #f1f1f1;
    color: #283447;
}

/* .logo {
    margin-right: 36px;
} */
/* test */


.navlink-custom {
    font-family: "Helvetica Neue", Helvetica;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #283447;
    margin-right: 36px;
}

.btn-login {
    cursor: pointer;    
    object-fit: contain;
    border-radius: 5px;
    border: solid 1px #2e95c8;
    background-color: #ffffff;
    padding: 5px 15px 5px 15px;
}

.login {
    font-family: "Helvetica Neue", Helvetica;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2e95c8;
    padding: 0px 10px 0px 10px;
  }
  span.headerlogo {
    color: #4285f4;
    font-weight: 600;
}
a.logo-style.navbar-brand {
    font-size: 35px;
}
button.btnlogout {
    background: #4285f4;
    border-radius: 0.5rem;
    height: 42px;
    border: 2px;
    color: #FFFFFF;
    float: right;
    width: 130px;
    font-size: smaller;
}
li.setting.nav-item {
    margin-right: 20px;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
}
li.home.nav-item {
    margin-right: 20px;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
}

.logotitle{
  margin: 0;
  margin-top: 25px;
  font-family: BuenosAiresTrial-bold;
  font-size: 2vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #2a3445;
  
}



.centertext{
    width: 100%;
  height: auto;
  font-family: BuenosAiresTrial;
  font-size: 1.45vw;
  font-weight: normal;  
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #2a3445;
  margin-bottom:0px;
  margin-top: 30px;
} 
 img.logoi{
    height: auto;
    margin-top: 25px;
}
img.logoii{
    height: auto;
   margin-top: 25px;
  
} 
.butDisplay
{
  
  margin-top: 30px;

}
.butHide
{
  display:none;
}

.but1{
    border: 1px solid #2a3445;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    background-color: #ffd8d3;
    border-radius: 5px;
    color: #2a3445;
    width:100%;
    height: 46px;
}
.but2{
  width:100%;
    height: 46px;
    background-color: #75c9cd;
    font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  border-radius: 5px;
  border: 1px solid #75c9cd;
  color: #283447;
}

@media (max-width:767px){
  .centertext{
    display:none;
  }
}

@media (max-width:767px){
  img.logoi{
    float: right;
  }
}


@media (min-width:768px){
  .logoi{
    display:none;
  }
}

@media (max-width:768px){
  .logotitle{
    font-size:2vh;
  }
}
@media (max-width:768px){
  .centertext{
    font-size:1.45vh;
  }
}



@media (max-width:767px){
  .logotitle{
    font-size:5vw;
  }
}


@media (max-width:650px){
  .logotitle{
    font-size:5vw;
  }
}
