.mainlogin{
    width: 100%;
    height: 100vh;
    background: #ffddd8;
  }
  .formbodymain.row {
    justify-content: center;
    width: 100%;
    margin-left: 0px;
}
.welcomeheading.row {
    justify-content: center;
    margin-top: 10rem;
}
.Welcometext {
  font-family: 'BuenosAiresTrial-bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
   ;
  color: #283447;
}
  .loginbutton {
    width: 10%;
    height: 35px;
    background-color: #75ced2;
    margin-top: 15px;
    color: #283447;
    border: none;
  }
  input.passwordfields.form-control {
    width: 100%;
    margin-top: 10px;
    width: 100%;
    height: 45px;
    border: none;
    font-family: 'BuenosAiresTrial';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    color: #283447;
    padding-left: 5%;
}
input.emailinput.form-control {
  background: #FFFFFF;
    margin-top: 5px;
    width: 100%;
    height: 45px;
    border: none;
    font-family: 'BuenosAiresTrial';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    color: #283447;
    padding-left: 5%;

}
.forgot.row {
    justify-content: center;
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    color: #283447;
}

button.loginhomebtn.btn.btn-secondary {
  width: 100%;
  height: 45px;
  margin-top: 1rem;
  border: 0px solid #283447;
  background-color: #75d0d4;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
  text-align: center;
}
.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.overlay-box {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: white; background: #666666; opacity: .8;
  z-index: 1000;
}
form.formclass {
  width: 40%;
}