.settingmain.row {
  background: #f1f1f1;
  height: 100%;
  min-height: 100vh;
  margin-top: 0rem;
}
.settingprotion1.row {
  margin-top: 1rem;
  width: 95%;
  margin-left: 3%;
}
.settingprotion2.row {
  margin-top: 5rem;
  width: 100%;
  margin-left: 0px;
  justify-content: centers;
}
.topheadingsetting {
  width: 100%;
  height: auto;
  font-family: "BuenosAiresTrial-Bold";
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
}
.formsetting.row {
  width: 95%;
  margin-left: 3%;
}
label.settingname-label {
  width: 100%;
  height: 18px;
  font-family: "BuenosAiresTrial-Bold";
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin-left: 10px;
  color: #283447;
  margin-top: 1rem;
  border: 1px;
}
input#settinguserName {
  width: 90%;
  font-family: "BuenosAiresTrial-Bold";
  color: #283447;
  border: none;
}

button.savesetting.btn.btn-secondary {
  background-color: #75d0d4;
  border: 1px;
  width: auto;
  height: 40px;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
  /* padding: 20%; */
}
.settingsaverow.row {
  width: 100%;
  text-align: end;
  margin: 5% 1%;
}
.data1data2.row {
  width: 90%;
  margin-left: 0px;
}
.data1row.row {
  border: none;
  width: auto;
}
.data2row.row {
  width: auto;
  margin-left: 1%;
  border: none;
}
select#targetinput {
  border: none;
  width: 90%;
}
input#startdateinput {
  border: none;
  width: 100%;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
}

input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

input[type="date"]::-webkit-outside-spin-button {
  display: none;
}
input#enddateinput {
  border: none;
  width: 100%;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
select#countryinput {
  border: none;
  width: 90%;
}
.agencycampaignprotion2 {
  justify-content: center;
}

body {
  background: #f1f1f1;
}

.react-autosuggest__container {
  position: relative;
  width: 100%;
  justify-content: center;
}

.react-autosuggest__input {
  width: 90%;
  height: 40px;
  font-family: "BuenosAiresTrial";
  margin-top: 1rem;
  padding: 0px 10px;
  /* font-family: Dosis; */
  font-weight: 300;
  font-size: 15px;
  background-color: #ffffff;
  border: 1px;
  border-radius: 4px;
  color: #283447;
}

/* .react-autosuggest__input--focused {
        outline: none;
      }
      
      .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      
      .react-autosuggest__suggestions-container {
        display: none;
      }*/

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 90%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: "BuenosAiresTrial";
  font-weight: normal;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  color: #283447;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
/*       
      .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
      } */

.cityzip-label-span {
  font-family: "BuenosAiresTrial";
  /* font-weight: bold; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-size: 15px;
  margin-top: 1%;
  margin-left: 2%;
}
td.col-11.socialInput {
  padding: 1px 8px 1px 1px;
  border: none;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  /* border-top: 1px solid #dee2e6; */
}
.crosssighns.row {
  z-index: 1;
  margin-left: -8%;
  margin-top: 7px;
  cursor: pointer;
}
tr.row.Social:hover {
  background: none;
}
