@media (min-width: 1800px){

  .createListing>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .listingadmain>.offset-lg-1:first-of-type {
      margin-left: 25.333333%;
      max-width: 30%;
  }

  .listingadmain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .listingadstate.row>.col>.createmanully.row{
    justify-content: flex-start;
  }

  .listingadstate.row>.col>.listingadbtns.row{
    padding-left: 40%;
  }
}

@media (max-width: 1800px) and (min-width: 1600px){

  .createListing>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .listingadmain>.offset-lg-1:first-of-type {
      margin-left: 25.333333%;
      max-width: 30%;
  }

  .listingadmain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .listingadstate.row>.col>.createmanully.row{
    justify-content: flex-start;
  }

  .listingadstate.row>.col>.listingadbtns.row{
    padding-left: 35%;
  }
}

@media (max-width: 1600px) and (min-width: 1450px){

  .createListing>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .listingadmain>.offset-lg-1:first-of-type {
      margin-left: 25.333333%;
      max-width: 30%;
  }

  .listingadmain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .listingadstate.row>.col>.createmanully.row{
    justify-content: flex-start;
  }

  .listingadstate.row>.col>.listingadbtns.row{
    padding-left: 30%;
  }
}

@media (max-width: 1450px) and (min-width: 1150px){

  .createListing>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .listingadmain>.offset-lg-1:first-of-type {
      margin-left: 20.333333%;
      max-width: 30%;
  }

  .listingadmain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .listingadstate.row>.col>.createmanully.row{
    justify-content: flex-start;
  }

  .listingadstate.row>.col>.listingadbtns.row{
    padding-left: 25%;
  }
}

@media (max-width: 1150px) and (min-width: 1000px){

  .createListing>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .listingadmain>.offset-lg-1:first-of-type {
      margin-left: 20.333333%;
      max-width: 30%;
  }

  .listingadmain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

}

@media (max-width: 1000px) and (min-width: 800px){

  .createListing>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .listingadmain>.offset-lg-1:first-of-type {
      margin-left: 16.333333%;
      max-width: 30%;
  }

  .listingadmain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .listingadstate.row>.col>.createmanully.row{
    justify-content: flex-start;
  }

  .listingadstate.row>.col>.listingadbtns.row{
    padding-left: 5%;
  }
}

@media (max-width: 800px) and (min-width: 770px){

  .createListing>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .listingadstate.row>.col>.createmanully.row{
    justify-content: flex-start;
  }

  .listingadstate.row>.col>.listingadbtns.row{
    //padding-left: 5%;
  }
}

@media (max-width: 770px) and (min-width: 600px){

  .createListing>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .listingadstate.row>.col>.createmanully.row{
    justify-content: flex-start;
  }

  .listingadstate.row>.col>.listingadbtns.row{
    padding-left: 50%;
  }
}
