.newstatisticsmain.row {
    background: #f1f1f1;
    height: 100%;
    /* min-height: 100vh;  */
    margin-top: 0rem;
}
.newstatisticsmain1.row {
  background: #f1f1f1;
  margin-top: 0rem;
}
.newstatisticsprotion1.row {
    background: #f1f1f1;
    margin-top: 2rem;
    width: 100%;
    margin-left: 0px;
}
.newstatisticsprotion2.row {
  background: #f1f1f1;
  margin-top: 2rem;
  width: 100%;
  margin-left: 0px;
}
.newstatisticstopheading{
  width: 40%;
  height: auto;
  font-family: 'BuenosAiresTrial-Bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
}
.newstatisticsdstate.row {
    width: 100%;
    margin-left: 0px;
    margin-top: 2rem;
}
.mobilepreview.row {
  width: 100%;
  padding: 30px;
}
.mobilepreviewVendor.row {
  width: 100%;
  padding: 30px;
  display: block;
}
.agencyimage {
  width: 120px;
  height: 120px;
}
.imageagency.row {
  display: flex;                         
  /* justify-content: center;               */
  margin-left: 185px;
  align-items: flex-start;
}
.chartContainerRowVendor.row {
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  max-height: 400px;
  margin-top: -250px;
  margin-left: 30px;
  margin-bottom: 4em;
}
@media only screen and (max-width: 1040px) {
  .chartContainerRowVendor.row {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    max-height: 400px;
    margin-top: -100px;
    margin-left: 30px;
    margin-bottom: 4em;
  }
}
.chartContainerRow.row {
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  max-height: 400px;
  margin-top: -130px;
  margin-left: 30px;
}
.statsChart {
  display: flex;                      
  justify-content: flex-start;              
  align-items: flex-start;
}
.newstatisticsnumber.row {
  font-family: 'BuenosAiresTrial-Bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
}
.newstatisticsaddress.row {
  font-family: 'BuenosAiresTrial-bold';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
}
.newstatisticstextheading.row {
  font-family: 'BuenosAiresTrial-bold';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
    margin-top: -7%;

}
.newstatisticstext.row {
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
  margin-top: -5%;
  width: 95%;
}
button.newstatisticsshare.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: auto;
    height: 45px;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
}
.newstatisticspdf-btn-wrapper {
    position: relative;
    overflow: hidden;
    /* display: inline-block; */
    /* justify-content: center; */
    width: 100%;
    height: 45px;
    margin-left: 0px;
  }
  
.newstatisticspdf-btn {
    width: auto;
    border: 1px solid #283447;
    color: #283447;
    background-color: #f1f1f1;
    padding: 8px 20px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
  font-style: normal;
  border-radius: 3%;
  }
  .newstatisticspdf-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  .newstatisticsbtns.row {
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
    margin-top: 2em;
    margin-bottom: 2em;
}
.newstatisticsstatistics.row {
    margin-top: 8rem;
    width: 100%;
    margin-left: 0px;
    justify-content: center;
}
.btnsinfo.row {
  font-family: 'BuenosAiresTrial';
    font-weight: normal;
    font-size: 15px;
    color: #283447;
}
button.dashboardshareinfo.btn.btn-secondary {
    background-color: #283447;
    border: 1px;
    width: auto;
    height: 45px;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #FFFFFF;
}
.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.overlay-box {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: white; background: #666666; opacity: .8;
  z-index: 1000;
}
.recharts-wrapper.newstatisticsstatistics {
  margin-top: 10rem;
}
/* div#reactgooglegraph-1 {
  margin-top: 5rem;
} */