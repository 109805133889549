.namecampaignmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.namecampaignprotion1.row {
    margin-top: 2rem;
    width: 100%;
    margin-left: 0px;
}
.namecampaignprotion2.row {
    margin-top: 7rem;
    width: 100%;
    margin-left: 0px;
    justify-content: center;
}
.topheadingnamecampaign{
    width: 100%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
   ;
  color: #283447;
}
.formnamecampaign.row {
    width: 100%;
    margin-left: 0px;
}
label.namecampaign-label {
    width: 100%;
    height: 18px;
  font-family: 'BuenosAiresTrial-Bold';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    margin-left: 10px;
    color: #283447;
    margin-top: 1rem;
    border: 1px;
}
.campaignsbtn{
  font-family: 'BuenosAiresTrial-Bold';
    font-weight: bold;
    font-size: 25px;
    color: #283447;
}
.campaignstatestss{
    width: 100%;
margin-left: 0px;
margin-top: 2rem;
}
.campaignsname-wrapper{
    position: relative;
overflow: hidden;
/* / display: inline-block; /
/ justify-content: center; / */
width: 100%;
margin-left: 0px;
}
.campaignnames-btnn{
    width: auto;
border: 1px solid #283447;
color: #283447;
background-color: #f1f1f1;
padding: 8px 20px;
font-size: 15px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
border-radius: 3%;
}
.campaignsname-wrapper input[type=submit] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
button.sharecampaignnames.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: auto;
    color: #283447;
    font-size: 13px;
  font-family: 'BuenosAiresTrial-Bold';
    margin-left: 5rem;
}
.calendersrow{
    margin-left: 4px;
    width: 100%;
}
.createad-btns.row{
font-family: 'BuenosAiresTrial';
font-weight: bold;
font-size: 25px;
color: #283447;
}