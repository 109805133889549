@import url('https://fonts.googleapis.com/css?family=BuenosAiresTrial');

/* @font-face {
 font-family: 'BuenosAiresTrial-Bold';
  src: local('BuenosAiresTrial-Bold'), url(./components/Fonts/BuenosAiresTrial-Bold.otf) format('opentype');
}
body {
  margin: 0;
  padding: 0;
  font-family: "BuenosAiresTrial-Bold";
}
@font-face {
font-family: 'BuenosAiresWeb-Bold';
src: local('BuenosAiresWeb-Bold'), url(./components/Fonts/BuenosAires-Bold.otf) format('opentype');

}
body {
  margin: 0;
  padding: 0;
  font-family: "BuenosAiresTrial-Bold";
} */
@font-face {
  font-family: 'BuenosAiresTrial-Bold';
    /* src: local('BuenosAiresWeb-Bold'), url(./components/Fonts/BuenosAiresWeb-Bold.eot) format('eot'); */
    src: local('BuenosAiresTrial-Bold'), url(./components/Fonts/BuenosAires-Bold.otf) format('opentype');
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "BuenosAiresTrial-Bold";
  }

  @font-face {
    font-family: 'BuenosAires-Bold';
      /* src: local('BuenosAires-Bold'), url(./components/Fonts/BuenosAires-Bold.otf) format('otf'); */
      src: local('BuenosAires-Bold'), url(./components/Fonts/BuenosAires-Bold.otf) format('opentype');
    }
    body {
      margin: 0;
      padding: 0;
      font-family: "BuenosAires-Bold";
    }

  @font-face {
    font-family: 'BuenosAiresTrial';
      src: local('BuenosAiresTrial'), url(./components/Fonts/BuenosAiresTrial-Book.otf) format('opentype');
    
    }
    body {
      margin: 0;
      padding: 0;
      font-family: "BuenosAiresTrial";
    }
      @font-face {
      font-family: 'BuenosAiresTrialWeb-SemiBold';
        src: local('BuenosAiresTrialWeb-SemiBold'), url(./components/Fonts/BuenosAiresTrialWeb-SemiBold.otf) format('opentype');
      
      }
      body {
        margin: 0;
        padding: 0;
        font-family: "BuenosAiresTrialWeb-SemiBold";
      } 

      @font-face {
        font-family: 'BuenosAiresTrial-Book';
          src: local('BuenosAiresTrial-Book'), url(./components/Fonts/BuenosAiresTrial-Book.otf) format('opentype');
        
        }
        body {
          margin: 0;
          padding: 0;
          font-family: "BuenosAiresTrial-Book";
        } 

        @font-face {
          font-family: 'BuenosAiresTrial-SemiBold';
            /* src: local('BuenosAiresWeb-Bold'), url(./components/Fonts/BuenosAiresWeb-Bold.eot) format('eot'); */
            src: local('BuenosAiresTrial-Bold'), url(./components/Fonts/BuenosAiresTrial-SemiBold.otf) format('opentype');
          }
          body {
            margin: 0;
            padding: 0;
            font-family: "BuenosAiresTrial-SemiBold";
          }
   
          @font-face {
            font-family: 'BuenosAiresTrialWeb-Light';
              /* src: local('BuenosAiresWeb-Bold'), url(./components/Fonts/BuenosAiresWeb-Bold.eot) format('eot'); */
              src: local('BuenosAiresTrialWeb-Light'), url(./components/Fonts/BuenosAiresTrialWeb-Light.otf) format('opentype');
            }
            body {
              margin: 0;
              padding: 0;
              font-family: "BuenosAiresTrialWeb-Light";
            }

          