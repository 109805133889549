.soldoutmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh; 
    margin-top: 0rem;
}
.soldoutprotion1.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
}
.soldouttopheading {
  width: 55%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
  margin-left: 2%;
}
.soldoutstate.row {
    width: 100%;
    margin-left: 0px;
    margin-top: 2rem;
  
}
.soldoutcenter.row {
    justify-content: center;
}
.soldoutnewbottom{
    width: 90%;
    border-radius: 3% ;
    margin-top: 4%;
}
.titleofchangecampaigntype {
  font-size: 15px;
  font-weight: bold;
  margin-left: 0%;
  margin-top: 3%;
  color: #283447;
font-family: 'BuenosAiresTrial-Bold';
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #FFFFFF;
  width: 100%;
  padding-left: 7%;
  padding-top: 5%;
  padding-bottom: 5%;
}
.chooseimagecss.row {
    width: 100%;
    background: #ffded9;
    margin-left: 0px;
    justify-content: center;
    padding-top: 7%;
}
.campaigntypetext.row {
    width: 100%;
    background: #ffded9;
    margin-left: 0px;
    justify-content: center;
    padding-top: 7%;
    padding-left: 2%;
  font-family: 'BuenosAiresTrial';
    font-size: 15px;
}
.campaigntypebtmbutton.row {
    width: 100%;
    background: #283447;
    color: #FFFFFF;
    margin-left: 0px;
    justify-content: center;
    padding-top: 4%;
    padding-left: 4%;
    padding-right: 4%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-family: Helvetica;
    font-size: 15px;

}
.soldout-wrapper{
    position: relative;
    overflow: hidden;
    /* display: inline-block; */
    justify-content: center;
    /* align-self: center; */
    width: 100%;
    margin-left: 0px;
}
.soldout-wrapper input[type=submit] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
button.soldout-btn-css.btn.btn-secondary {
  margin-top: 10rem;
  width: auto;
height: 40px;
border: 1px solid #283447;
background-color: #f1f1f1;
font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
border-radius: 5%;
}
/* .button.soldout-btn-css.btn.btn-secondary{
width: auto;
height: 40px;
border: 1px solid #283447;
background-color: #f1f1f1;
font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
border-radius: 5%;
margin-top: 10rem;
} */
.soldout-btns.row{
  font-family: 'BuenosAiresTrial';
    font-weight: bold;
    font-size: 25px;
    color: #283447;
    }
    button.sharesoldout.btn.btn-secondary {
        background-color: #75d0d4;
        border: 1px;
        width: auto;
        height: 40px;
        font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
  margin-top: 10rem;
    }