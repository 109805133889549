.cardportionlast.row {
    width: auto;
    margin-right: 15%;
    margin-left: 15%;
  }

.nametext>p>span{
  float: left;
  clear: both;
  margin-left:16px;
}

.dashboardmain>.offset-lg-1>.dashboardprotion2 {
    width: 112px;
    height: 102px;
    float: right;
    margin-bottom: 1%;
}

.dashboardmain>.offset-lg-1>.dashboardprotion2>.imagesize {
    width: 100%;
    height: 100%;
    border-radius: 10%;
    //object-fit: cover;
}

.react-aspect-ratio-placeholder:first-child{
  position: relative;
}

@media (min-width: 1800px){

    .dashboardmain>.offset-lg-1:first-of-type {
        margin-left: 17.333333%;
        max-width: 16%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2) {
        margin-left: 14%;
        flex: 0 0 33.333333%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
        margin-left: 8%;
    }

    .dashboard>.navbar-custom-1 {
      padding-left: 18%;
      padding-right: 18%;
      padding-bottom: 5%;
      padding-top: 1%;
      margin-top:0%;
    }
  }

  @media (max-width: 1800px) and (min-width: 1600px){

    .dashboardmain>.offset-lg-1:first-of-type {
        margin-left: 17.333333%;
        max-width: 16%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2) {
        margin-left: 14%;
        flex: 0 0 33.333333%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
        margin-left: 8%;
    }

    .dashboard>.navbar-custom-1 {
      padding-left: 18%;
      padding-right: 17%;
      padding-bottom: 5%;
      padding-top: 1%;
      margin-top:0%;
    }
  }

  @media (max-width: 1600px) and (min-width: 1450px){

    .dashboardmain>.offset-lg-1:first-of-type {
        margin-left: 17.333333%;
        max-width: 16%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2) {
        margin-left: 14%;
        flex: 0 0 33.333333%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
        margin-left: 8%;
    }

    .dashboard>.navbar-custom-1 {
      padding-left: 18%;
      padding-right: 18%;
      padding-bottom: 5%;
      padding-top: 1%;
      margin-top:0%;
    }
    .dashboardmain>.offset-lg-1:first-of-type>.dashboardleft>a>.createcampaign.btn.btn-secondary {
      font-size: 12px;
    }
  }

  @media (max-width: 1450px) and (min-width: 1150px){

    .dashboardmain>.offset-lg-1:first-of-type {
        margin-left: 17.333333%;
        max-width: 16%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2) {
        margin-left: 14%;
        flex: 0 0 33.333333%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
        margin-left: 8%;
    }

    .dashboard>.navbar-custom-1 {
      padding-left: 23%;
      padding-right: 20%;
      padding-bottom: 5%;
      padding-top: 1%;
      margin-top:0%;
    }

    .dashboardmain>.offset-lg-1:first-of-type>.dashboardleft>a>.createcampaign.btn.btn-secondary {
      font-size: 12px;
    }
  }

  @media (max-width: 1150px) and (min-width: 1000px){

    .dashboardmain>.offset-lg-1:first-of-type {
        margin-left: 22.333333%;
        max-width: 22%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2) {
        margin-left: 1%;
        flex: 0 0 33.333333%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
        margin-left: 8%;
    }

    .dashboard>.navbar-custom-1 {
      padding-left: 23%;
      padding-right: 20%;
      padding-bottom: 5%;
      padding-top: 5%;
      margin-top:0%;
    }
    .dashboardmain>.offset-lg-1:first-of-type>.dashboardleft>a>.createcampaign.btn.btn-secondary {
      font-size: 12px;
    }
  }

  @media (max-width: 1000px) and (min-width: 950px){

    .dashboardmain>.offset-lg-1:first-of-type {
        margin-left: 20.333333%;
        max-width: 16%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2) {
        margin-left: 5%;
        flex: 0 0 33.333333%;
    }

    .dashboardmain>.offset-lg-1:nth-of-type(2)>.offset-lg-1 {
        margin-left: 8%;
    }

    .dashboard>.navbar-custom-1 {
      padding-left: 21%;
      padding-right: 20%;
      padding-bottom: 5%;
      padding-top: 1%;
      margin-top:0%;
    }
  }
