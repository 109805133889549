.parentDisable{
    position: fixed;
    top: 0;
    left: 0;
    background: #666;
    opacity: 0.8;
    z-index: 998;
    height: 100%;
    width: 100%;
  }
  
  .overlay-box {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: white; background: #666666; opacity: .8;
    z-index: 1000;
  }
  .headerlogostyle.row {
    background: #f1f1f1;
    width: 100%;
    margin-left: 0px;
}
img.logostylefasad {
  margin-left: 5%;
  margin-top: 1%;
}
a.logostylefasad {
  margin-left: 5%;
  margin-top: 1%;
}