.changecampaigntypemain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh; 
    margin-top: 0rem;
}
.changebudgetprotion1.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 15px;
}
.changebudgettopheading {
    width: 100%;
    height: auto;
    font-family: 'BuenosAiresTrial-Bold';
    font-size: 43px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #283447;
}
.changebudgetstate.row {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.changecampaigntypelistbottom.row {
    justify-content: center;
}
.changebudgetcardsizebottom{
    width: 100%;
    border-radius: 3% ;
    margin: 4%;
}
.titleofchangebudget {
    font-size: 15px;
    font-weight: bold;
    margin: 0%;
    color: #283447;
    font-family: 'BuenosAiresTrial-Bold';
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #FFFFFF;
    width: 100%;
    padding-left: 7%;
    padding-top: 5%;
    padding-bottom: 5%;
}
.chooseimagecss.row {
    width: 100%;
    background: #ffded9;
    margin-left: 0px;
    justify-content: center;
    padding-top: 7%;
}
.campaigntypetext.row {
    width: 100%;
    background: #ffded9;
    margin-left: 0px;
    justify-content: center;
    padding-top: 7%;
    padding-left: 2%;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    color: #283447;
}
.campaigntypetextestimate.row {
    width: 100%;
    background: #ffded9;
    margin-left: 0px;
    justify-content: center;
    padding-top: 3%;
    padding-left: 2%;
  font-family: 'BuenosAiresTrial-Bold';
    font-size: 15px;
    color: #283447;
    font-weight: bold;


}
.campaigntypebtmbutton.row {
    width: 100%;
    background: #283447;
    color: #FFFFFF;
    margin-left: 0px;
    justify-content: center;
    padding-top: 4%;
    padding-left: 4%;
    padding-right: 4%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-family: 'BuenosAiresTrial';
    font-size: 15px;
}
.newbudgetprice-label-span {
    font-family: 'BuenosAiresTrial';
    /* font-weight: bold; */
    font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
    font-size: 15px;
    margin-top: 1%;
    margin-left: 2%;
}
.tickImage {
    height: 15px;
    width: 15px;
}