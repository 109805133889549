.part1{
  padding-left:16%;
  padding-right:16%;
  margin:0;
  background-color: #ffdad5;
}

.part2{
  /* padding-left:8.33%;
  padding-right:8.33%; */
  padding-top: 10%;
  padding-bottom: 10%;
  /* margin:0;
  background-color: #ffdad5; */
}

.part3{
  padding-left:8.33%;
  padding-right:8.33%;
  padding-top: 5%;
  margin:0;
  background-color: #ffdad5;
}
.part4{
  padding-left:8.33%;
  padding-right:8.33%;
  margin:0;
}


.mobileimage {
  height: auto;
  max-width: 100%;
  float: right;
}

.tempp{
  width:90%;
  margin-left:auto;
  margin-right:auto;
}
.bfooter {
  background-color: #ffdad5;
  margin: 0;
  padding: 5% 16%;
  text-align: center;
}
.btn .btn-secondary.footerSignupBtn  {
  background-color: '#ffdad5';
  font-size: 15;
  border: '1px solid #2a3445';
  width: 102;
  height: 46;
  border-radius: 5;
  font-family: 'BuenosAiresTrial';
}

.landingpagemain {
  width: 100%;
  margin-left: 0px;
  background-color: #ffdad5;
  height: 100%;
}
.lessworkTextMain {
  padding: 15% 0;
  height: 100%;
}
.iii{
  width:100%;
  max-width:100%;
  max-height:auto;
  height: 100%;
}
.lessworkText {
  margin: 0;
  font-family: BuenosAiresTrial-bold;
  font-size: 4vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #2a3445;
}
.readmorebutton.row {
  margin-left: 0;
  margin-right: 0;
}


button.readmore.btn.btn-secondary {
  height: 46px;
  background-color: #2a3445;
  width: 110%;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 3%;
 
}

button.readmore1.btn.btn-secondary {
  margin-top: 5%;
  height: 46px;
  background-color: #2a3445;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
}
.trusted {
  margin-top: 10%;
}
img.imgstyles {
  padding: 5%;
  margin-bottom: 9%;
}
.realestate.row {
  margin-top: 20%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial-bold";
  font-size: 2.5vw;
  margin-left: 0;
  margin-right: 0;
}
.realestate1.row {
  width: 100%;
  
  margin-top: -1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: "BuenosAiresTrial-bold";
  font-size: 40px;
}
.text1.row {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 1.2vw;
  margin: 0
} 
.readmorebutton2 {
  margin: 0
}
.text2.row {
 

  margin-top: -1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 1.6vw;
}

button.readmore2.btn.btn-secondary {
  height: 46px;
  background-color: #2a3445;
  width: 110%;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
}

img.imgstyles31 {
  padding: 0%;
  margin-top: 12%;
}

img.imgstyles32 {
  padding: 0%;
  margin-top: 12%;


}
img.imgstyles333 {
  padding: 0%;
  margin-top: 9%;

 
}


img.imgstyles33 {
  margin-top: 12%;
  margin-left: 32%;
}

.imgstyles5 {
  margin-top: 15%;
  height: auto;
  max-width: 100%;
}


.text4.row {
  width: 100%;
  margin-top: 20%;
  margin-left: 0;
  margin-right: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial-bold";
  font-size: 2.5vw;
}
.text5.row {
  width: 100%;
  margin-top: -1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f3333;
  font-family: "BuenosAiresTrial-bold";
  font-size: 43px;
}
.text6.row {
  margin-left: 0;
  margin-right: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 1.2vw;
  margin-top: 5px;
}
.text7.row {
 

  margin-top: -15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 1.6vw;
}

.imagestyle6.row {
  width: 100%;
  background: #eeeeee;
  margin: 0;
  justify-content: center;
  padding-top: 7%;
}
.imagestyle6s.row {
  width: 100%;
  background: #eeeeee;
 
  justify-content: center;
  padding-top: 7%;
}

.imagestyle66.row {
  width: 100%;
  background: #eeeeee;
  
  justify-content: center;
  padding-top: 7%;
}

.background2 {
  margin: 0px;
  background-color: #eeeeee;
  padding-left: 16%;
  padding-right: 16%;
}
.text8 {
  margin-top: 3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial-bold";
  font-size: 20px;
  text-align: center;
  justify-content: center;
}
.text9.row {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 20px;
  text-align: center;
}
.text10.row {
  width:100%;
  margin-top: -1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 22px;
  text-align: left;
  justify-content: center;
}
.section6.row {
  background-color: #ffdad5;
  padding: 5% 16%;
  margin: 0px;
  justify-content: center;
}
.text11.row {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #2a3445;
  font-family: "BuenosAiresTrial-bold";
  font-size: 2.5vw;
  text-align: center;
  justify-content: center;
}
.text12.row {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #2a3445;
  font-family: "BuenosAiresTrial";
  font-size: 20px;
  text-align:center;
  justify-content:center;
}
.imagestyle7.row {
  /* width: 100%; */

  justify-content: center;
  padding-top: 3%;
 
}
.img7{
  width:50%;
  height:50%;
}
.section7.row {
  margin: 0;
  padding: 5% 16%;
  background-color: #f4a68e;
}
.text13.row {
  margin: 0;
  width: 100%;
  max-width: 100%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #2a3445;
  font-family: BuenosAiresTrial-bold;
  font-size: 2.5vw;
  text-align: center;
  justify-content: center;
}

.trusted1 {
  width: 100%;
  height: 5rem;
}

.imagestyle8{
  justify-content: center;
  padding-top: 10%;
}
.section8.row {
  margin-top: 0rem;
  width: 100%;
  margin-left: 0px;
  height: 100%;
  background-color: #f4a68e;
  padding-bottom: 6%;
}
.text14.row {
  width: 100%;
  margin-left: 0px;
  margin-top: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: "BuenosAiresTrial-bold";
  font-size: 30px;
  text-align: center;
  justify-content: center;
}
.text15.row {
  width: 100%;
  margin-left: 0px;
  margin-top: 0rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: "BuenosAiresTrial-bold";
  font-size: 18px;
  text-align: center;
  justify-content: center;
}
.text16.row {
  width: 100%;
  height: 10vh;
  margin-left: 0px;
  margin-top: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: "BuenosAiresTrial";
  font-size: 15px;
  justify-content: center;
}
.text17 {
  width: 100%;
  margin-left: 0px;
  margin-top: 13rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: "BuenosAiresTrial-bold";
  font-size: 18px;
}
.text18.row {
  width: 100%;
  margin-left: 0px;
  margin-top: -1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: "BuenosAiresTrial";
  font-size: 15px;
  /* justify-content: center; */
}
.text19.row {
  width: 100%;
  margin-left: 0px;
  margin-top: -1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: "BuenosAiresTrial";
  font-size: 15px;
}
.section9.row {
  margin-top: 0rem;
  width: 100%;
  margin-left: 0px;
  height: 100%;
  background: #ffddd8;
}
span.text20 {
  width: 100%;
  margin-top: 0rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: "BuenosAiresTrial";
  font-size: 15px;
}
.text21.row {
  width: 100%;
  margin-left: 0px;
  margin-top: 0rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: "BuenosAiresTrial-bold";
  font-size: 15px;
}
.readmorebutton3.row {
  margin-top: 2rem;
  width: 100%;
  margin-left: 0px;
}
.imagestyle9.row {
  width: 100%;
  margin-top: 3rem;
  justify-content: center;
  font-size: 70px;
  color: #f7be7d;
}
span.centerdot {
  color: #000000;
}
img.imgstylesmak {
  /* margin: auto; */
  margin-top: 10px;
  margin-left: 20px;
}
img.imgstylesnotar {
  margin-left: 25px;
  margin-top: 0px;
}
img.imgstylesskan {
  margin-top: 12px;
}

.aaa {
  width: 100%;
  height: 13px;
}

.bbb {
  width: 100%;
  height: 13px;

  margin-top: 70px;
  position: absolute;
}

.ccc {
  width: 100%;
  height: 18px;

  position: absolute;
  margin-top: 123px;
}

/* this is for large devices font */

@media (min-width: 1900px) {
  .lessworkText {
  font-size:4.5vw;
  }
}

@media (min-width: 1900px) {
  .realestate.row {
  font-size:3vw;
  }
}

@media (min-width: 1900px) {
  .text4.row {
  font-size:3vw;
  }
}

@media (min-width: 1900px) {
  .text1.row {
  font-size:1.4vw;
  }
}
@media (min-width: 1900px) {
  .text6.row {
  font-size:1.4vw;
  }
}

@media (min-width: 1900px) {
  .text8.row {
  font-size:22px;
  }
}

@media (min-width: 1900px) {
  .text9.row {
  font-size:22px;
  }
}

@media (min-width: 1900px) {
  .text11.row {
  font-size:3vw;
  }
}

@media (min-width: 1900px) {
  .text12.row {
  font-size:22px;
  }
}

@media (min-width: 1900px) {
  .text13.row {
  font-size:3vw;
  }
}

@media (min-width: 1900px) {
  .text111.row,.text9s.row {
  font-size:22px;
  }
}

/* this is for small devices font */


@media (max-width: 767px) {
  .lessworkText {
  font-size:6vw;
  
  }
}

@media (max-width: 375px) {
  .lessworkText {
  font-size:6vw;
  
  }
}


@media (max-width: 767px) {
  .realestate.row,.text4.row{
   font-size: 4vh;
   margin-top: 5%;

  }
}

@media (max-width: 767px) {
  .text1.row,.text6.row{
   font-size:2.5vh;
   
  }
}


@media (max-width: 375px) {
  .realestate.row,.text4.row{
   font-size: 3.5vh;
   margin-top: 5%;

  }
}

@media (max-width: 375px) {
  .text1.row,.text6.row{
   font-size:2vh;
   
  }
}

@media (max-width: 767px) {
  .text8,.text9{
   font-size:22px;
   
  }
}

@media (max-width: 375px) {
  .text8,.text9{
   font-size:20px;
   
  }
}

@media (max-width: 767px) {
  .text11.row{
   font-size: 5vh;
  }
}

@media (max-width: 767px) {
  .text12.row{
   font-size: 3.5vh;
  }
}

@media (max-width: 767px) {
  .text13.row{
   font-size: 5vh;
   
  }
}


@media (max-width: 375px) {
  .text11.row{
   font-size: 4.5vh;
  }
}

@media (max-width: 375px) {
  .text12.row{
   font-size: 3vh;
  }
}

@media (max-width: 375px) {
  .text13.row{
   font-size: 4.5vh;
   
  }
}

@media (max-width: 767px) {
  .text111.row,.text9s.row{
   font-size: 2.5vh;
   
  }
}

@media (max-width: 375px) {
  .text111.row,.text9s.row{
   font-size: 2vh;
   
  }
}

@media (max-width: 767px) {
  button.readmore1.btn.btn-secondary{
   display: none;
   
  }
}

@media (max-width: 767px) {
  .lessworkTextMain{
   
   padding-bottom:0;
   padding-top:0;
   
  }
}
/* There is max width quiries */




/* 
@media (min-width: 1900px) {
  .text4.row {
  font-size: 47.5px;
  }
}
@media (min-width: 1900px) {
  .text6.row {
  font-size: 24.5px;
  }
}
@media (min-width: 1900px) {
  .text7.row {
  font-size: 24.5px;
  }
}

@media (max-width: 767px) {
  .lessworkTextMain {
    padding: 35% 0;
  }
}
@media (max-width: 767px) {
  .readmore1 {
    font-size: 5.5vw;
  }
}

@media (min-width: 1900px) {
  .lessworkText{
   font-size: 84px;
  }
}

@media (min-width: 1900px) {
  .realestate.row {
   font-size: 47.5px;
  }
}

@media (min-width: 1900px) {
  .text1.row {
   font-size: 24.5px;
  }
}
@media (min-width: 1900px) {
  .text2.row {
   font-size: 24.5px;
  }
}

@media (min-width: 1900px) {
  .text13.row {
   font-size: 47.5px;
  }
} */






/* this is mobile renponsive settings */





@media (max-width: 767px) {
  .text2.row{
   font-size:3vh;
   
  }
}



@media (max-width: 767px) {
  .text7.row{
   font-size:2vh;
  
  }
}







@media (max-width: 750px) {
  .img7{
   width:100%;
   
  }
}


@media (max-width: 395px) {
  button.readmore1.btn.btn-secondary{
   
   font-size:13px;
  
  }
}

@media (max-width: 365px) {
  button.readmore1.btn.btn-secondary{
   
   font-size:12px;
   width:100%;
  }
}

@media (max-width: 350px) {
  button.readmore1.btn.btn-secondary{
   
   font-size:12px;
  padding:0;
  }
}




@media (max-width: 765px) {
  .tempp{
   
width:100%;


  }
}

.text1111 {
  width: 100%;
  margin: 0;
  margin-top: 3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;

  color: #283447;
  font-family: "BuenosAiresTrial-bold";
  font-size:22px;
  text-align: center;
  justify-content: center;
}

.text111 {
  margin: 0;
  margin-top: 3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  color: #283447;
  font-family: "BuenosAiresTrial-bold";
  font-size: 20px;
  text-align: center;
  justify-content: center;
}
.textf {
  width: 100%;
  margin-left: 0px;
  margin-top: 5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: "";
  font-size: 40px;
  text-align: center;
  justify-content: center;
}

.text88.row {
  
  margin-left:-8%;
  margin-top: 3rem;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial-bold";
  font-size: 22px;
  text-align: left;
  justify-content: center;
}
.text99.row {
  width: 100%;

  margin-top: -3px;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size:22px;
  text-align: left;
  justify-content: center;
}
.text100.row {
  width: 100%;

  margin-top: -1rem;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 22px;
  text-align: left;
  justify-content: center;
}

.text888.row {

margin-left:-8%;
  margin-top: 3rem;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial-bold";
  font-size: 22px;
  text-align: left;
  justify-content: center;
}
.text999.row {
  

  margin-top: -3px;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 22px;
  text-align: left;
  justify-content: center;
}
.text1000.row {
 
  
  margin-top: -1rem;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size:22px;
  text-align: left;
  justify-content: center;
}

.text9s {
  margin: 0;
  font-weight: notmal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 20px;
  justify-content: center;
  text-align: center;
}

.text9ss.row {
  width: 110%;
  margin-left: 4%;
  margin-top: -15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;

  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 22px;
  justify-content: center;
}

.text9sas.row {
  width: 100%;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;

  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 22px;
  justify-content: center;
}
.text9sass.row {
  width: 100%;

  margin-top: -15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;

  color: #283447;
  font-family: "BuenosAiresTrial";
  font-size: 22px;
  justify-content: center;
}

.awien {
  width: 100%;
  height: 80px;
  background-color: #f4a68e;
}
