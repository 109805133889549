.cardCustomization {
    border: 0px;
    border-radius: 6rem;
    width: 75%;
    margin: 5% auto 5% auto;
}

.cardImageOverlayCustomiztion {
    margin: 27% 7% 25% 7%;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cardImageOverlayCustomiztion::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.cardImageOverlayCustomiztion {
    -ms-overflow-style: none;
}

.headerCustomization {
    margin-top: 3%;
}

.cardBodyCustomiztion {
    padding: 0px;
}

.previewAdImage {
    height:auto;
    max-width: 100%;
}

.previewAdFirstBlock{
    z-index: 1;
    position: sticky;
    margin: 20% 11% 0 22%;
}

.previewAdPersonIcon {
    height: auto;
    max-width: 100%;
    /* background-color: #ff9d8b; */
    border-radius: 50%;
    margin: 15%;
}

.previewAdPersonName {
    font-family: Arial;
    font-size: 9.3px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1a1a1a;
    margin: 5% 0 0 1%;
}

.previewAdPersonSponsored {
    height: auto;
    font-family: Arial;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #c5c5c5;
    margin: 1% 0 0 1%;
}

.previewAdPersonDot {
    background-color: #1a1a1a;
    width: 3.2px;
    height: 3.2px;
    margin: 30% auto 0 auto;
    border-radius: 50%;
    box-shadow: -4px 0px 0px #1a1a1a, 4px 0px 0px #1a1a1a;
}

.previewAdSecondBlock{
    z-index: 1;
    position: sticky;
    margin: 3% 11% 0 22%;
}

.previewAdPersonDescription {
    font-family: Arial;
    margin: 3%;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #1a1a1a;
}

.previewAdThirdBlock {
    z-index: 1;
    position: sticky;
    margin: 3% 21% 0 22%;
    justify-content: center;
}

.previewAdDetailImage {
    /* height:auto; */
     width: 100%;
    height: 100%;
    /* margin: 3% auto 0 auto; */
}

.cardFooterCustomization {
    background-color: #F2F3F5;
    padding-bottom: 0rem;
}

.footerTextUpper {
    font-family: Arial;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-transform: uppercase;
    text-align: left;
    color: #1a1a1a;
}

.footerTextMiddle {
    margin-top: 2%;
    font-family: Arial;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #1a1a1a;
}

.footerTextLower {
    margin-top: 2%;
    font-family: Arial;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #1a1a1a;
}

.adPreviewCustomButton.btn{
    margin-top: 10%;
    width: 100%;
    height: auto;
    padding: 4px;
    font-family: Arial;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #1a1a1a;
}

.previewAdLikeComments {
    width: 100%;
    height: auto;
    margin-top: 2%;
}
