.agencycampaignmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh;
    margin-top: 0rem;
}
.agencycampaignprotion1.row {
    margin-top: 2rem;
    width: 100%;
    margin-left: 4%;
}
.agencycampaignprotion2.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
}
.agencyheading2.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
    font-weight: bold;
}
.agencyheading3.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;
}
.agencyheading4.row {
    width: 50%;
    margin-left: 0px;
    font-size: 12px;

}
.agencyheading5.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;

}
.agencyheading6.row {
    /* margin-top: 1rem; */
    width: 100%;
    margin-left: 0px;
    font-size: 12px;

}
.agencyheading7.row {
    margin-top: -1rem;
    width: 100%;
    margin-left: 0px;
    font-size: 12px;

}
.topheadingagencycampaign{
    width: 100%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
}
.agencystate.row {
    width: 100%;
    margin-left: 0px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
button.agencysubmit.btn.btn-secondary {
    background-color: #75d0d4;
    border: 1px;
    width: auto;
    color: #FFFFFF;
    font-size: 13px;
  font-family: 'BuenosAiresTrial-Bold';
    color: #283447;
    margin-left: 0rem;
    }
    .formagencycampaign {
        margin-left: 1%;
    }
    


    