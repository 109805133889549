.changecampaigntypemain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh; 
    margin-top: 0rem;
}
.changecampaigntypeprotion1.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
}
.changecampaigntypetopheading {
  width: 55%;
  height: auto;
  font-family: 'BuenosAiresTrial-bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
}
.changecampaigntypestate.row {
    width: 100%;
    margin-left: 0px;
    margin-top: 2rem;
    text-decoration: none;
}
.changecampaigntypelistbottom.row {
    justify-content: center;
    text-decoration: none;
}
.changecampaigntypecardsizebottom{
    width: 100%;
    border-radius: 3% ;
    margin: 4%;
}
.titleofchangecampaigntype {
    font-size: 15px;
    font-weight: bold;
    margin-left: 0%;
    margin-top: 3%;
    color: #283447;
  font-family: 'BuenosAiresTrial-Bold';
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #FFFFFF;
    width: 100%;
    padding-left: 7%;
    padding-top: 5%;
    padding-bottom: 5%;
}
.chooseimagecss.row {
    width: 100%;
    background: #ffded9;
    margin-left: 0px;
    justify-content: center;
    padding-top: 7%;
}
.campaigntypetext.row {
    width: 100%;
    background: #ffded9;
    margin-left: 0px;
    justify-content: center;
    padding-top: 7%;
    padding-left: 2%;
  font-family: 'BuenosAiresTrial';
    font-size: 15px;
}
.campaigntypetext3.row {
    width: 100%;
    background: #ffded9;
    margin-left: 0px;
    justify-content: center;
    padding-top: 7%;
    padding-left: 2%;
  font-family: 'BuenosAiresTrial-Bold';
    font-size: 16px;
}
.campaigntypebtmbutton.row {
    width: 100%;
    background: #283447;
    color: #FFFFFF;
    margin-left: 0px;
    justify-content: center;
    padding-top: 4%;
    padding-left: 4%;
    padding-right: 4%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-decoration: none;
}
