button.readmore.btn.btn-secondary {
    height: 46px;
    background-color: #2a3445;
    width: 100%;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;  
    color: #ffffff;
  }

@media (min-width: 769px) {
.modal-dialog {
    max-width: 100%;
    /* margin: 1.75rem auto; */
}}
