.newhowmain.row {
    background: #f1f1f1;
    height: 100%;
    min-height: 100vh; 
    margin-top: 0rem;
}
.newhowprotion1.row {
    margin-top: 1rem;
    width: 100%;
    margin-left: 0px;
}
.newhowtopheading {
  width: 55%;
  height: auto;
font-family: 'BuenosAiresTrial-Bold';
  font-size: 43px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #283447;
}
.newhowstate.row {
    width: 100%;
    margin-left: 0px;
    margin-top: 2rem;
  
}
.newhowimage1.row {
  font-family: 'BuenosAiresTrial-Bold';
    font-weight: bold;
    font-size: 25px;
    color: #283447;
    width: 100%;
    margin-left: 0px;
}
img.copyimagecenter {
    padding-top: 15%;
}
.howtext.row {
    width: 100%;
    margin-left: 0px;
    justify-content: center;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #283447;
}
.howtexturl.row {
    width: 100%;
    margin-left: 0px;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: BuenosAiresTrial;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
     ;
    color: #283447;
    margin-top: 4rem;
}
span.boldtext {
    color: #283447;
    font-weight: 700;
    font-size: 15px;
  font-family: 'BuenosAiresTrial-Bold';
}
