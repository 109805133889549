
.cardCustomization>.cardImageOverlayCustomiztion>.previewAdDetailImage{
  height: 52%;
  object-fit: contain;
}

@media (min-width: 1800px){

  .createafternote>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .createafternotemain>.offset-lg-1:first-of-type {
      margin-left: 25.333333%;
      max-width: 30%;
  }

  .createafternotemain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .cardCustomization {
      width: 50%;
      margin-left: 0%;
  }

  .createafternotestate>.col>.createafternotebtns>.createafternotesubmit {
    margin-left: 9.5rem;
  }
}

@media (max-width: 1800px) and (min-width: 1600px){

  .createafternote>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .createafternotemain>.offset-lg-1:first-of-type {
      margin-left: 25.333333%;
      max-width: 30%;
  }

  .createafternotemain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .cardCustomization {
      width: 55%;
      margin-left: 0%;
  }

  .createafternotestate>.col>.createafternotebtns>.createafternotesubmit {
    margin-left: 7.5rem;
  }
}

@media (max-width: 1600px) and (min-width: 1450px){

  .createafternote>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .createafternotemain>.offset-lg-1:first-of-type {
      margin-left: 25.333333%;
      max-width: 30%;
  }

  .createafternotemain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .cardCustomization {
      width: 60%;
      margin-left: 0%;
  }

  .createafternotestate>.col>.createafternotebtns>.createafternotesubmit {
    margin-left: 6.5rem;
  }
}

@media (max-width: 1450px) and (min-width: 1350px){

  .createafternote>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .createafternotemain>.offset-lg-1:first-of-type {
      margin-left: 20.333333%;
      max-width: 30%;
  }

  .createafternotemain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .cardCustomization {
      width: 65%;
      margin-left: 0%;
  }

}

@media (max-width: 1350px) and (min-width: 1250px){

  .createafternote>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .createafternotemain>.offset-lg-1:first-of-type {
      margin-left: 20.333333%;
      max-width: 30%;
  }

  .createafternotemain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .cardCustomization {
      width: 70%;
      margin-left: 0%;
  }

}

@media (max-width: 1350px) and (min-width: 1200px){

  .createafternote>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .createafternotemain>.offset-lg-1:first-of-type {
      margin-left: 20.333333%;
      max-width: 30%;
  }

  .createafternotemain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .cardCustomization {
      width: 75%;
      margin-left: 0%;
  }

  .createafternotestate>.col>.createafternotebtns>.createafternotesubmit {
    margin-left: 3.5rem;
  }

}

@media (max-width: 1200px) and (min-width: 1070px){

  .createafternote>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .createafternotemain>.offset-lg-1:first-of-type {
      margin-left: 20.333333%;
      max-width: 30%;
  }

  .createafternotemain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .cardCustomization {
      width: 85%;
      margin-left: 0%;
  }

  .createadnew-btn>.btn-secondary {
    font-size: 13px;
  }

  .createafternotestate>.col>.createafternotebtns>.createafternotesubmit {
    margin-left: 2.5rem;
  }

}

@media (max-width: 1070px) and (min-width: 980px){

  .createafternote>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .createafternotemain>.offset-lg-1:first-of-type {
      margin-left: 20.333333%;
      max-width: 30%;
  }

  .createafternotemain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .cardCustomization {
      width: 95%;
      margin-left: 0%;
  }

  .createadnew-btn>.btn-secondary {
    font-size: 12px;
  }

  .createafternotestate>.col>.createafternotebtns>.createafternotesubmit {
    margin-left: 2.5rem;
  }

}

@media (max-width: 980px) and (min-width: 767px){

  .createafternote>.navbar-custom-1 {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 1%;
    margin-top:0%;
  }

  .createafternotemain>.offset-lg-1:first-of-type {
      margin-left: 20.333333%;
      max-width: 30%;
  }

  .createafternotemain>.offset-lg-1:nth-of-type(2) {
      margin-left: 0%;
      flex: 0 0 33.333333%;
  }

  .cardCustomization {
      width: 100%;
      margin-left: 0%;
  }

  .createadnew-btn>.btn-secondary {
    font-size: 10px;
  }
  .createafternotestate>.col>.createafternotebtns>.createafternotesubmit {
    margin-left: 1.5rem;
  }
}
